// render Group Cards using useUser() hook

import React from "react";
import { useUser } from "../core/UserContext";
import { Link, useNavigate } from "react-router-dom";
import GroupCard from "./GroupCard";
import { Rating } from "../types/standardTypes";
import { getRatingColor } from "./Info";

export const GroupCluster = () => {
    const { userProfile, groupsSubbed } = useUser();
    const navigate = useNavigate();

    const [showPersonal, setShowPersonal] = React.useState(false);
    const [showGroup, setShowGroup] = React.useState(false);

    if (userProfile == null) {
        return null;
    }

    return (
        <div className="group-cluster" style={{
            position: "absolute", top: 0, minWidth: 100, left: 0, zIndex: 1, padding: '2px', fontSize: '11px',
        }}>
            <div style={{float: 'left', paddingRight: '10px'}}>
            <div style={{ fontWeight: 'bold', fontSize: '10px' }}
                onClick={() => {
                    setShowPersonal(!showPersonal);
                }}            
            >🌟 Ratings ({userProfile.ratings?.length ?? 0})</div>
                {showPersonal ? (
                    <div style={{fontSize: '10px', paddingLeft: '5px'}}>
                        <div>☑ To-Go (<span style={{color: getRatingColor(Rating.TO_GO)}}>{userProfile.ratings?.filter(place => place.rating == Rating.TO_GO).length ?? 0}</span>)<span style={{ textAlign: 'right', float: 'right' }}>📅</span></div>
                        <div>☑ Dislike (<span style={{color: getRatingColor(Rating.DISLIKE)}}>{userProfile.ratings?.filter(place => place.rating == Rating.DISLIKE).length ?? 0}</span>) <span style={{ textAlign: 'right', float: 'right' }}>👎</span></div>
                        <div>☑ OK (<span style={{color: getRatingColor(Rating.OK)}}>{userProfile.ratings?.filter(place => place.rating == Rating.OK).length ?? 0}</span>) <span style={{ textAlign: 'right', float: 'right' }}>🤷</span></div>
                        <div>☑ Like (<span style={{color: getRatingColor(Rating.LIKE)}}>{userProfile.ratings?.filter(place => place.rating == Rating.LIKE).length ?? 0}</span>) <span style={{ textAlign: 'right', float: 'right' }}>👍</span></div>
                        <div>☑ Love (<span style={{color: getRatingColor(Rating.FAVORITE)}}>{userProfile.ratings?.filter(place => place.rating == Rating.FAVORITE).length ?? 0}</span>) <span style={{ textAlign: 'right', float: 'right' }}>❤️</span></div>
                    </div>
                ) : null}
            </div>

            <div style={{float: 'left', paddingRight: '5px'}}>
                <div style={{ fontWeight: 'bold', fontSize: '10px' }}
                    onClick={() => {
                        setShowGroup(!showGroup);
                    }}            
                >🌠 Groups ({groupsSubbed?.length ?? 0})</div>
                {/* <div className="group-cluster__header">
                    <h2>Groups</h2>
                    <Link to="/groups">View All</Link>
                </div> */}
                {showGroup ? (
                    <div className="group-cluster__content" style={{paddingLeft: '5px'}}>
                        {groupsSubbed?.map((group, i) => (
                            <GroupCard key={i} group={group} onClick={() => navigate(`/view/group/${group.gid}`)} />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}