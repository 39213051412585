import React, { useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Search } from './Search';
import { useUser } from '../core/UserContext';
import { getRatingColor, Info } from './Info';
import { MapOptionStyles } from './MapConsts';
import './Marker.css';
import { PlaceRating, Rating } from '../types/standardTypes';
import { throttle } from 'lodash';
import { Alert, Snackbar } from '@mui/material';
import { getIcon } from './Icons';

const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyBBsJWHzuPz8F59WLPS0_EH2ssr08YYAHI';

// would recommend for jetstream (Family) or particular persons X
// "Private:  Next time you plan with X, use this recommendation."
// "Public:  Next time X plans, they will see your recommendations."

const containerStyle = {
    width: '100vw',
    height: '100vh',
    minWidth: '-webkit-fill-available',
    minHeight: '-webkit-fill-available',
    backgroundColor: "#000000"
};

const libraries: any[] = ['places'];

const defaults = {
    center: {
        lat: -33.865143,
        lng: 151.209900
    },
    zoom: 12
};

let geolocated = true;
if (navigator.geolocation) {
    function showPosition(position: GeolocationPosition) {
        defaults.zoom = 15;
        defaults.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
    }

    navigator.geolocation.getCurrentPosition(showPosition,
        () => {
            geolocated = false;
            console.log("Geolocation is not supported by this browser.");
        }
    );
}

interface Props {
    group?: string;
}

export const Map = React.memo((props: Props) => {
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [activePlaceId, setActivePlaceId] = React.useState<string | null>(null);
    const [activePlaceCount, setActivePlaceCount] = React.useState<number>(0);
    const [zoomLevel, setZoomLevel] = React.useState(defaults.zoom);
    const [moveCount, setMoveCount] = React.useState(0);
    const [snackBarOpen, setSnackBarOpen] = React.useState(true);
    const { userProfile, groupsSubbed } = useUser();

    const onClick = (val: google.maps.MapMouseEvent) => {
        console.log('onClick: ', val)

        // place is clicked
        const valAny = val as any;
        if (valAny.placeId != null) {
            setActivePlaceId(valAny.placeId);
            setActivePlaceCount(count => count + 1);
        }
        val.stop();
    }

    const onZoomChanged = () => {
        console.log('zoom changed: ', map?.getZoom() ?? zoomLevel);
        setTimeout(() => {
            setZoomLevel(map?.getZoom() ?? zoomLevel);
        }, 500);
    }

    const markers = useMemo(() => {
        const temp = {
            ...(!props.group && userProfile ? { [userProfile.uid]: userProfile.ratings } : {}),
            ...(props.group ? groupsSubbed?.find(g => g.gid === props.group)?.ratings : {})
        }

        // flatten into uid -> rating pair
        const pairs = Object.entries(temp).flatMap(([uid, ratings]) => {
            return ratings?.map(rating => ({ uid, rating })) ?? [];
        });

        // group by place id
        const grouped = pairs.reduce((acc, pair) => {
            const { uid, rating } = pair;
            if (acc[rating.placeId]) {
                acc[rating.placeId].push({ uid, rating });
            } else {
                acc[rating.placeId] = [{ uid, rating }];
            }
            return acc;
        }, {} as { [placeId: string]: { uid: string, rating: PlaceRating }[] });

        // conflict management rules.
        // 1. show only lowest rating. prefer myself.
        // 2. if lowest rating is "Like To Go" but someone else has a rating, show that rating instead unless it's me.
        // 3. if highest rating is "Favorite" but lowest rating is "Like", show "Favorite".
        const filtered = Object.entries(grouped).reduce((acc, [placeId, ratings]) => {
            const mine = ratings.find(r => r.uid === userProfile?.uid);
            const lowest = ratings.reduce((acc, item) => {
                if (item.rating.rating < acc.rating.rating) {
                    return item;
                }
                return acc;
            }, ratings[0]);

            const highest = ratings.reduce((acc, item) => {
                if (item.rating.rating > acc.rating.rating) {
                    return item;
                }
                return acc;
            }, ratings[0]);

            let final = mine ?? lowest;
            if (lowest.rating.rating >= Rating.TO_GO && highest.rating.rating === Rating.FAVORITE) {
                final = highest;
            }
            if (lowest.rating.rating === Rating.DISLIKE) {
                final = lowest;
            }
            if (mine?.rating.rating === Rating.TO_GO || mine?.rating.rating === final.rating.rating) {
                final = mine;
            }

            acc[final.uid] = acc[final.uid] ?? [];
            acc[final.uid].push(final.rating);
            return acc;
        }, {} as { [uid: string]: PlaceRating[] });

        return filtered;
    }, [userProfile, groupsSubbed, props.group]);

    // filter markers down to bounding box
    const newMarkers = useMemo(() => {
        if (map) {
            const bounds = map.getBounds();
            if (bounds) {
                const newMarkers = {};
                Object.entries(markers).forEach(([uid, ratings]) => {
                    newMarkers[uid] = ratings?.filter(rating => bounds.contains({ lat: rating.lat, lng: rating.lng }));
                });
                return newMarkers;
            }
        }

        return markers;
    }, [map, map?.getBounds(), markers, moveCount]);

    const onDrag = useCallback(throttle(() => setMoveCount(moveCount => moveCount + 1), 500), []);

    if (userProfile == null) {
        return null;
    }

    return (
        <LoadScript
            googleMapsApiKey={YOUR_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaults.center}
                zoom={defaults.zoom}
                onLoad={setMap}
                onDrag={onDrag}
                onClick={onClick}
                onZoomChanged={onZoomChanged}
                options={{
                    disableDefaultUI: true,
                    scrollwheel: true,
                    zoomControl: false,
                    minZoom: 3,
                    maxZoom: 20,
                    streetViewControl: false,
                    keyboardShortcuts: false,
                    fullscreenControl: false,
                    styles: MapOptionStyles,
                    gestureHandling: 'greedy',
                    backgroundColor: "#000000"
                }}
            >
                {!geolocated && (
                    <Snackbar open={snackBarOpen} sx={{ zIndex: 10000 }} autoHideDuration={10000} onClose={() => setSnackBarOpen(false)}>
                        <Alert severity="warning">
                            Please allow location access for more accurate results.
                            <br />
                            To allow location access, please click the lock icon in the address bar and select "Allow".
                        </Alert>
                    </Snackbar>
                )}                

                <Search />
                { /* Render markers for each ratings in the user */}
                {map && geolocated && (
                    // you are here marker
                    <Marker
                        position={{ lat: defaults.center.lat, lng: defaults.center.lng }}
                        icon={{
                            url: 'https://maps.gstatic.com/mapfiles/markers2/dd-via.png',
                            scaledSize: new google.maps.Size(16, 16),
                            labelOrigin: new google.maps.Point(16, 36),
                        }}
                        zIndex={2000}
                        title="You are here"
                    />
                )}
                {map && newMarkers && Object.entries(newMarkers).map(([uid, ratings]) => (
                    ratings && ratings.map((place) => (
                        <Marker
                            key={place.placeId}
                            position={{ lat: place.lat, lng: place.lng }}
                            onClick={() => {
                                setActivePlaceId(place.placeId);
                                setActivePlaceCount(count => count + 1);
                            }}
                            icon={{
                                url: getIcon(uid),
                                scaledSize: new google.maps.Size(24, 24),
                                labelOrigin: new google.maps.Point(16, 36),
                            }}
                            zIndex={getZIndex(place.rating)}
                            title={place.name}
                            options={{...getMarkerOptions(place, zoomLevel), optimized: true}}
                        />
                    ))
                ))}
                {activePlaceId && (
                    <Info placeId={activePlaceId} key={`${activePlaceId}-${activePlaceCount}`} group={props.group} />
                )}

            </GoogleMap>
        </LoadScript>
    )
});

const getMarkerOptions = (place: PlaceRating, zoomLevel: number) => {    
    let desirability = place.rating;
    
    // swap to go and like for better visibility
    if (place.rating === Rating.TO_GO) {
        desirability = Rating.LIKE;
    }
    else if (place.rating === Rating.LIKE) {
        desirability = Rating.TO_GO;
    }

    // hide markers that are too small
    if (zoomLevel <= 4 && desirability < 5) {
        return {
            label: undefined,
            visible: false,
        }
    }

    if (zoomLevel <= 8 && desirability < 4) {
        return {
            label: undefined,
            visible: false,
        }
    }

    if (zoomLevel <= 12 && desirability < 3) {
        return {
            label: undefined,
            visible: false,
        }
    }

    // level of details on labels
    if (zoomLevel <= 13 && desirability < 5) {
        return {
            label: undefined,
            visible: true,
        }
    }
    if (zoomLevel <= 14 && desirability < 4) {
        return {
            label: undefined,
            visible: true,
        }
    }
    if (zoomLevel <= 16 && desirability < 3) {
        return {
            label: undefined,
            visible: true,
        }
    }
    if (zoomLevel <= 17 && desirability < 2) {
        return {
            label: undefined,
            visible: true,
        }
    }

    return {
        label: {
            text: place.name,
            color: getRatingColor(place.rating),
            fontSize: getFontSize(place.rating),
            fontWeight: getFontWeight(place.rating),
            className: 'marker-label',
        },
        visible: true,
        collisionBehavior: 'OPTIONAL_AND_HIDES_LOWER_PRIORITY',
    }
}

const getZIndex = (rating: number) => {
    let newRating = rating;
    if (rating === Rating.TO_GO) {
        newRating = Rating.LIKE;
    }
    else if (rating === Rating.LIKE) {
        newRating = Rating.TO_GO;
    }

    return newRating * 100 + 1000;
}

const getFontWeight = (rating: number) => {
    switch (rating) {
        case Rating.FAVORITE:
        case Rating.TO_GO:
        case Rating.LIKE:
            return 'bold';
        default:
            return 'normal';
    }
}

const getFontSize = (rating: number) => {
    switch (rating) {
        case Rating.LIKE:
        case Rating.FAVORITE:
        case Rating.TO_GO:
            return '13px';
        case Rating.OK:
        default:
            return '11px';
    }
}