import { UID } from "../types/standardTypes";

export const getIcon = (uid: UID) => {
    // hash the email to get a consistent icon
        const hash = uid.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 3);


    const avatars = [
        'https://cdn-icons-png.flaticon.com/64/3069/3069172.png',
        'https://cdn-icons-png.flaticon.com/64/1864/1864514.png',
        'https://cdn-icons-png.flaticon.com/64/2153/2153090.png',
        'https://cdn-icons-png.flaticon.com/64/616/616412.png',
        'https://cdn-icons-png.flaticon.com/64/4322/4322991.png',
        'https://cdn-icons-png.flaticon.com/64/6583/6583465.png',
        'https://cdn-icons-png.flaticon.com/64/1864/1864472.png',
        'https://cdn-icons-png.flaticon.com/64/3940/3940417.png',
        'https://cdn-icons-png.flaticon.com/64/9392/9392845.png',
        'https://cdn-icons-png.flaticon.com/64/10292/10292931.png',
        'https://cdn-icons-png.flaticon.com/64/10284/10284820.png',
        'https://cdn-icons-png.flaticon.com/64/9339/9339351.png',
        'https://cdn-icons-png.flaticon.com/64/1998/1998642.png',
        'https://cdn-icons-png.flaticon.com/64/763/763755.png',
        'https://cdn-icons-png.flaticon.com/64/7432/7432635.png',
        'https://cdn-icons-png.flaticon.com/64/1308/1308845.png',
        'https://cdn-icons-png.flaticon.com/64/4600/4600417.png',
        'https://cdn-icons-png.flaticon.com/64/1810/1810917.png',
        'https://cdn-icons-png.flaticon.com/64/9339/9339269.png',
        'https://cdn-icons-png.flaticon.com/64/3940/3940412.png',
        'https://cdn-icons-png.flaticon.com/64/6359/6359408.png',
        'https://cdn-icons-png.flaticon.com/64/1326/1326388.png',
        'https://cdn-icons-png.flaticon.com/64/9308/9308872.png',
        'https://cdn-icons-png.flaticon.com/64/3940/3940421.png',
        'https://cdn-icons-png.flaticon.com/64/3500/3500458.png',
        'https://cdn-icons-png.flaticon.com/64/3940/3940415.png',
        'https://cdn-icons-png.flaticon.com/64/9708/9708789.png',
        'https://cdn-icons-png.flaticon.com/64/3558/3558255.png',
        'https://cdn-icons-png.flaticon.com/64/4775/4775484.png',
        'https://cdn-icons-png.flaticon.com/64/1326/1326401.png',
        'https://cdn-icons-png.flaticon.com/64/3687/3687893.png',
        'https://cdn-icons-png.flaticon.com/64/3940/3940410.png',
    ];

    return avatars[Math.abs(hash) % avatars.length];
}
