import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ThemeProvider from './ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider>
    <CssBaseline />
    <App />
  </ThemeProvider>
)
