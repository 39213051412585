export const MapOptionStyles: google.maps.MapTypeStyle[] = [
    { elementType: "all", stylers: [{ color: "white", visibility: "simplified" }] },
    { elementType: "geometry", stylers: [{ color: "#040f0e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#040f0e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#749875" }, { lightness: -50 }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#95b563" }, { lightness: -70 }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ffcc00" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    // {
    //     featureType: "poi",
    //     stylers: [{ visibility: "off" }],
    // },
    {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.business",
        stylers: [{ visibility: "simplified" }, { lightness: -70 }],
    },
    {
        featureType: "poi.government",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.medical",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.park",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi.sports_complex",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit",
        stylers: [{ visibility: "simplified" }, { lightness: -70 }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#28313e" }, { lightness: -50 }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }, { lightness: -50 }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8c8583" }, { lightness: -50 }],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ lightness: -50 }]
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#545845" }, { lightness: -40 }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }, { lightness: -40 }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }, { lightness: -40 }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }, { lightness: -30 }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#a59563" }, { lightness: -30 }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#07060c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
]