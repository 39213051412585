import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./auth/firebase";
import { getUser, getUserGroups, upsertUser } from "./db/firebase";
import React from "react";
import { GroupDocData, UserDocData } from "../types/standardTypes";


interface UserContextType {
    userProfile: UserDocData | null;
    groupsSubbed: GroupDocData[] | null;
    setUserProfile: React.Dispatch<React.SetStateAction<UserDocData | null>> | null;
}

const UserContext = createContext<UserContextType>({userProfile: null, setUserProfile: null, groupsSubbed: null});

// User context provider
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<UserDocData | null>(null);    
    const [groups, setGroups] = useState<GroupDocData[] | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const data = await getUser(user.uid);
                if (data) {
                    console.log('User', data)
                    setUser(data);

                    const groups = await getUserGroups(user.uid);
                    console.log('Groups', groups)
                    setGroups(groups);
                }
                else {
                    // console.error("User not found in database");
                    console.log("User not found in database - creating a new one!");
                    await upsertUser({uid: user.uid, name: user.displayName ?? '', email: user.email ?? '', provider: "google", photoURL: user.photoURL ?? undefined});
                    setUser(data);
                    setGroups([]);
                }
            }
            else {
                setUser(null);
                setGroups(null);
            }
        });
        return unsubscribe;
    }, []);

    if (user === null) {
        return null;
    }

    return (
        <UserContext.Provider value={{userProfile: user, setUserProfile: setUser, groupsSubbed: groups}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
}
