import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    updateDoc,
    setDoc,
} from "firebase/firestore";
import { app } from "../app";
import { GroupDocData, PlaceRating, UserDocData } from "../../types/standardTypes";

const db = getFirestore(app);

export const upsertUser = async (user: UserDocData) => {
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
        await insertUser(user);
    }
    else if (docs.docs.length === 1) {
        const doc = docs.docs[0];
        await setDoc(doc.ref, user, { merge: true });
    }
}

export const insertUser = async (user: UserDocData) => {
    await addDoc(collection(db, "users"), user);
}

const getUserDoc = async (uid: string) => {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
        return null;
    }
    return docs.docs[0];
}

export const getUser = async (uid: string) => {
    const doc = await getUserDoc(uid);
    if (doc === null) {
        return null;
    }
    return doc.data() as UserDocData;
}

export const addRemoveRating = async (uid: string, rating: PlaceRating, setUser: any) => {
    console.log("addRemoveRating", uid, rating);
    const user  = await getUserDoc(uid);

    if (user) {
        const ratings : PlaceRating[] = user.data().ratings ?? [];
        console.log(ratings)
        const existingRating = ratings.find(r => r.placeId === rating.placeId);
        if (existingRating) {
            if (existingRating.rating === rating.rating) {
                ratings.splice(ratings.indexOf(existingRating), 1);
            }
            else {
                existingRating.rating = rating.rating;
            }
        }
        else {
            ratings.push(rating);
            console.log(ratings)
        }
        
        updateDoc(user.ref, { ratings });
        setUser({...user.data(), ratings});  // FIXME.  This is a hack to update the user context
    }
}

export const getUserGroups = async (uid: string) => {
    const q = query(collection(db, "groups"), where("contributors", "array-contains", uid));
    const docs = await getDocs(q);
    return docs.docs.map(doc => { return {gid: doc.id, ...doc.data()} }) as GroupDocData[];
}