import { useQuery } from "@tanstack/react-query";

async function fetchPlace(placeId: string) {
    console.log('fetching place', placeId)
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    const promise = new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        service.getDetails({ placeId }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                resolve(place);
            } else {
                reject(status);
            }
        });
    });

    const value = await promise;
    return value;
}

export const usePlaceQuery = (placeId: string) => {
    return useQuery<google.maps.places.PlaceResult, string>(['place', placeId], () => fetchPlace(placeId));    
}