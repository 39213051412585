import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { GroupDocData } from '../types/standardTypes';
import React from 'react';
import { getIcon } from './Icons';

interface Props {
  group: GroupDocData;
  onClick: () => void;
}

const GroupCard = ({ group, onClick }: Props) => {
  const { name, photoURL, contributors } = group;

  return (
    <StyledCard onClick={onClick}>
      <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <CardActionArea>
          {/* <CardMedia
            component="img"
            alt={name}
            height="28"
            image={photoURL}
            title={name}
          /> */}
          <CardContent
            sx={{
              // position: 'absolute',
              // bottom: 0,
              // left: 0,
              // width: '100%',
              // bgcolor: 'rgba(0, 0, 0, 0.54)',
              // color: 'white',
              // backgroundColor: 'rgba(0, 0, 0, 1)',
              padding: '0px',
            }}
          >
            <Typography variant="body1" sx={{fontSize: '10px'}}>
              👥 {name}
              <span style={{ textAlign: 'right', float: 'right' }}>
              {contributors.map((contributor) => (
                <img src={getIcon(contributor)} width="12" height="12" />
              ))}
            </span>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Box>
    </StyledCard>
  );
};

const StyledCard = styled(Card)({  
  maxWidth: 100,
  minWidth: 90,
  cursor: 'pointer',
});

export default GroupCard;