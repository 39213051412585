import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "../core/auth/firebase";
import { UserProvider } from "../core/UserContext";
import { LinearProgress } from "@mui/material";

export const ProtectedLayout = () => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    if (loading) return <LinearProgress />;

    return (
        <UserProvider>
            <Outlet />
        </UserProvider>
    )
};