// user document

export type UID = string;
export type GID = string;
export type PlaceID = string;

export interface UserDocData {
    uid: UID;
    name: string;
    email: string;
    provider: string;
    photoURL?: string;

    ratings?: PlaceRating[];
}

export interface GroupDocData {
    gid: GID;
    name: string;
    ratings: GroupRecommendations;
    contributors: UID[]; // uid
    ownerUID: UID;
    photoURL?: string;
}

export interface PlaceRating extends Place {
    rating: Rating;    
}

export interface Place {
    name: string;
    lat: number;
    lng: number;
    placeId: PlaceID;
}

export enum Rating {
    DISLIKE = 1,
    OK = 2,
    TO_GO = 3,
    LIKE = 4,
    FAVORITE = 5,
}

export interface GroupRecommendations {
    [uid: UID]: PlaceRating[];
}