import { Autocomplete, useGoogleMap } from '@react-google-maps/api';
import React, { useCallback } from 'react';
import { Info } from './Info';

import { Input, TextField, debounce } from '@mui/material';

export const Search = () => {
    const map = useGoogleMap();

    const ref = React.useRef<Autocomplete>(null);
    const [visible, setVisible] = React.useState(false);

    const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete>();

    const onLoad = (val: google.maps.places.Autocomplete) => {
        // bind object
        setAutocomplete(val)

        console.log('loaded')

        setTimeout(() => {
            const data = map?.data as any;
            console.log("map", map);
            console.log("data", data)
            console.log("features", data.map.features);
        }, 2000);

    }

    const onPlaceChanged = () => {
        setVisible(false);

        if (autocomplete != null) {
            console.log(autocomplete.getPlace())
            const latlng = autocomplete.getPlace().geometry?.location;
            if (latlng && map) {
                map.panTo(latlng);
                map.setCenter(latlng);
                map.setZoom(19);

                console.log('Resetting to');
                setTimeout(() => {
                    setVisible(true);
                }, 100);
            }

            // reset search input
            const el = document.getElementById('search-input') as HTMLInputElement;
            if (el) {
                el.value = '';
            }
            
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const onBoundsChanged = useCallback(debounce(() => {
        console.log('bounds changed')
        ref.current?.state.autocomplete?.setBounds(map?.getBounds());
    }, 1000), []);

    React.useEffect(() => {
        map?.addListener("bounds_changed", onBoundsChanged);
    }, []);

    if (map == null) {
        return (<>Loading</>);
    }

    return (<>
        <Autocomplete
            ref={ref}
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            bounds={map?.getBounds()}
            options={{
                types: ["restaurant", "cafe", "bakery", "bar", "food"], // ["(regions)"],
            }}
        >
            <Input
                type="text"
                placeholder="Find a restaurant"
                id="search-input"
                size='small'
                style={{
                    // boxSizing: `border-box`,
                    // border: `1px solid transparent`,
                    width: `38%`,
                    height: `24px`,
                    padding: `0 px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `12px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "fixed",
                    right: "0%",
                    top: "4px",
                    // marginLeft: "-300px"
                }}
            />
        </Autocomplete>

        {visible && autocomplete?.getPlace() != null && autocomplete.getPlace().place_id != null && (<>
            <Info placeId={autocomplete.getPlace().place_id as string} />
        </>)}
    </>);
};