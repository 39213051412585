import { Favorite, PlaylistAdd, SentimentNeutral, SentimentVeryDissatisfied, SentimentVerySatisfied, Share } from '@mui/icons-material';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { InfoWindow, Marker } from '@react-google-maps/api';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../core/auth/firebase';
import { addRemoveRating } from '../core/db/firebase';
import { useUser } from '../core/UserContext';
import { Place, Rating } from '../types/standardTypes';
import { usePlaceQuery } from '../data/Queries';
import { styled } from '@mui/system';

export const getRatingColor = (rating: Rating) => {
    switch (rating) {
        case Rating.TO_GO:
            return "#ff33ff"; // purple.
        case Rating.DISLIKE:
            return "#999999"; // gray.
        case Rating.OK:
            return "#6666ff"; // blue.
        case Rating.LIKE:
            return "#33cc66"; // green.
        case Rating.FAVORITE:
            return "#ffcc00"; // yellow.
        default:
            return "gray";
    }
}

const defaultColor = "#dddddd";


const RatingsBar = (props: { place: google.maps.places.PlaceResult, group?: string }) => {
    const [user, loading] = useAuthState(auth);
    const { userProfile, groupsSubbed, setUserProfile } = useUser();

    if (user == null || loading) {
        return <div>Loading...</div>;
    }

    const myPlace: Place = {
        name: props.place.name!,
        lat: props.place.geometry?.location?.lat() ?? 0,
        lng: props.place.geometry?.location?.lng() ?? 0,
        placeId: props.place.place_id ?? "",
    };

    const myRating = userProfile?.ratings?.find(r => r.placeId === myPlace.placeId)?.rating;

    const addDislike = () => { addRemoveRating(user.uid, { ...myPlace, rating: Rating.DISLIKE }, setUserProfile); };
    const addEnqueue = () => { addRemoveRating(user.uid, { ...myPlace, rating: Rating.TO_GO }, setUserProfile); };
    const addOK = () => { addRemoveRating(user.uid, { ...myPlace, rating: Rating.OK }, setUserProfile); };
    const addLike = () => { addRemoveRating(user.uid, { ...myPlace, rating: Rating.LIKE }, setUserProfile); };
    const addFavorite = () => { addRemoveRating(user.uid, { ...myPlace, rating: Rating.FAVORITE }, setUserProfile); };

    const myRatingColor = getRatingColor(myRating ?? Rating.TO_GO);


    const playlistStyle = { color: myRating === Rating.TO_GO ? myRatingColor : defaultColor };
    const dislikeStyle = { color: myRating === Rating.DISLIKE ? myRatingColor : defaultColor };
    const okStyle = { color: myRating === Rating.OK ? myRatingColor : defaultColor };
    const likeStyle = { color: myRating === Rating.LIKE ? myRatingColor : defaultColor };
    const favoriteStyle = { color: myRating === Rating.FAVORITE ? myRatingColor : defaultColor };

    // find ratings for this place from group context
    const groupRatings: Rating[] = [];
    
    groupsSubbed?.forEach(group => {
        if (props.group && group.gid !== props.group) {
            return;
        }
        for (const uid in group.ratings) {
            // ignore my own ratings
            if (uid === user.uid) {
                continue;
            }
            if (group.ratings.hasOwnProperty(uid)) {
                const rating = group.ratings[uid].find(r => r.placeId === myPlace.placeId);
                if (rating) {
                    groupRatings.push(rating.rating);
                }
            }
        }
    });

    return (<div>
        {/* <GroupInfo ratings={groupRatings} /> */}
        <Tooltip title="Want to go">
            <Badge badgeContent={groupRatings.filter(r => r === Rating.TO_GO).length} variant="dot" color="secondary" overlap="circular">
                <StyledIconButton>
                    <PlaylistAdd onClick={addEnqueue} sx={{ color: playlistStyle }} />
                </StyledIconButton>
            </Badge>
        </Tooltip>
        <Tooltip title="Dislike - waste of time">
            <Badge badgeContent={groupRatings.filter(r => r === Rating.DISLIKE).length} variant="dot" color="secondary" overlap="circular">
                <StyledIconButton>
                    <SentimentVeryDissatisfied onClick={addDislike} sx={{ color: dislikeStyle }} />
                </StyledIconButton>
            </Badge>
        </Tooltip>
        <Tooltip title="OK - not bad, not great - wouldn't mind">
            <Badge badgeContent={groupRatings.filter(r => r === Rating.OK).length} variant="dot" color="secondary" overlap="circular">
                <StyledIconButton>
                    <SentimentNeutral onClick={addOK} sx={{ color: okStyle }} />
                </StyledIconButton>
            </Badge>
        </Tooltip>
        <Tooltip title="Like - would return again">
            <Badge badgeContent={groupRatings.filter(r => r === Rating.LIKE).length} variant="dot" color="secondary" overlap="circular">
                <StyledIconButton>
                    <SentimentVerySatisfied onClick={addLike} sx={{ color: likeStyle }} />
                </StyledIconButton>
            </Badge>
        </Tooltip>
        <Tooltip title="Favorite - will take a trip to come back">
            <Badge badgeContent={groupRatings.filter(r => r === Rating.FAVORITE).length} variant="dot" color="secondary" overlap="circular">
                <StyledIconButton >
                    <Favorite onClick={addFavorite} sx={{ color: favoriteStyle }} />
                </StyledIconButton>
            </Badge>
        </Tooltip>
    </div>);
}

interface Props {
    placeId: string;
    group?: string;
}

export const Info = (props: Props) => {
    const { placeId } = props;
    const placeQuery = usePlaceQuery(placeId);
    const place = placeQuery.data;

    const [isOpen, setIsOpen] = React.useState(true);

    const onLoad = (infoWindow: any) => {
        console.log('infoWindow: ', infoWindow)
    }

    if (place == null) {
        return null;
    }

    // show infowindow when marker is clicked
    const onClick = (e: google.maps.MapMouseEvent) => {
        setIsOpen(true);
        e.stop();
    }

    // check for "restaurant", "bakery", "bar", "cafe", "meal_takeaway"
    const isSupermarket = place.types?.some(t => ["grocery_or_supermarket"].includes(t));
    const isFood = !isSupermarket && place.types?.some(t => ["restaurant", "food", "bakery", "bar", "cafe", "meal_takeaway"].includes(t));

    if (!isFood) {
        return null;
    }

    return (<>
        <Marker
            position={place.geometry!.location as any}
            onClick={onClick}
            // icon={{
            //     // url: 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png',
            //     // scaledSize: new google.maps.Size(32, 32),
            //     // origin: new google.maps.Point(0,0),
            //     //anchor: new google.maps.Point(32, 32),
            // }}
            options={{
                visible: false,
            }}
        >

            {isOpen && (
                <InfoWindow
                    onLoad={onLoad}
                    position={place.geometry?.location}
                    onContentChanged={() => {
                        console.log('onContentChanged')
                    }}
                    onCloseClick={() => {
                        console.log('onCloseClick');
                        setIsOpen(false);
                    }}
                    options={{
                        minWidth: 280,
                    }}
                >
                    <div style={{ color: 'black', fontSize: '11px' }}>
                        <b>{place.name}</b><br />
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>
                            <a href={place.url} target="_blank" rel="noreferrer">{place.formatted_address}</a>
                        </span><br />
                        {place.rating} rating with {place.user_ratings_total} reviews.
                        {/* {place.opening_hours?.open_now ? 'Open now' : 'Closed'}. */}
                        {place.price_level && [...Array(place.price_level)].map((x, i) =>
                            <span key={i}>&#128176;</span>
                        )}
                        &nbsp;
                        <a href={`tel:${place.formatted_phone_number}`}>Call</a> &nbsp;
                        {place.website && (<a href={place.website} target="_blank" rel="noreferrer">Website</a>)} &nbsp;
                        <br />
                        {/* {place.types?.join(', ')} */}
                        <span style={{ float: 'right', color: 'black' }}>
                            <ShareButton url={place.url!} name={place.name!} />
                        </span>
                        {isFood && (
                            <RatingsBar place={place} group={props.group}/>
                        )}
                        {/* <b>Share with: X</b> */}
                        {place.photos?.map((photo, index) => {
                            if (index > 1) {
                                return null;
                            }
                            return <img key={index} style={{ width: '100%', height: 130, objectFit: 'cover' }} src={photo.getUrl({ maxWidth: 900, maxHeight: 450 })} alt="" />
                        })}
                    </div>
                </InfoWindow>)}
        </Marker>
    </>);
};

export const ShareButton = (props: { url: string, name: string }) => {
    // use navigator.share if available

    if (!navigator.share) {
        return null;
    }

    return (<Tooltip title="Share">
        <StyledIconButton>
            <Share sx={{ width: 24, height: 24, color: 'orange' }}
                onClick={() => {
                    navigator.share(
                        {
                            title: props.name,
                            url: props.url,
                        }
                    )
                        .then(() => console.log('Successful share'))
                        .catch((error) => console.log('Error sharing', error));
                }} />
        </StyledIconButton>
    </Tooltip>);
}



const StyledIconButton = styled(IconButton)({
    padding: 8,
});