import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProtectedLayout } from './components/ProtectedLayout'
import Login from './core/auth/Login'
import Register from './core/auth/Register'
import TestApp from './TestApp'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24 * 7,
    },
  },
})
export default function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <Box sx={{ flexGrow: 1, height: '100%', backgroundColor: 'black' }}>
        <TopBar/>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            {/* <Route exact path="/reset" element={<Reset />} /> */}
            <Route path="/view" element={<ProtectedLayout />} >
              <Route path="" element={<TestApp />} />
              <Route path="group/:groupId" element={<TestApp/>} />
            </Route>
            <Route path="/dashboard" element={<ProtectedLayout />} >
              <Route path="" element={<TestApp />} />
            </Route>
          </Routes>
        </Router>
      </Box>
    </QueryClientProvider>
  )
}

const TopBar = () => {
    //   {/*<AppBar sx={{ backgroundColor: 'transparent', background: '#000', boxShadow: 'none', width: '50%', left: '0' }} position="fixed">*/}
    // {/* <Toolbar sx={{ backgroundColor: 'transparent', background: 'transparent' }} style={{ minHeight: '32px' }}> */}
    //   {/* <Typography variant="h6" sx={{ flexGrow: 1, color: '#ffff00', backgroundColor: 'transparent' }}> */}
    //     {/* <img src={process.env.PUBLIC_URL + '/cloud-emoji.png'} width="16" height="16" alt="Jetstreamr" /> */}
    //     {/* <img src="https://cdn-icons-png.flaticon.com/512/3069/3069172.png" width="16" height="16" alt="Stanley" />
    //     <img src="https://cdn-icons-png.flaticon.com/512/1864/1864514.png" width="16" height="16" alt="Amanda" />
    //     <img src="https://cdn-icons-png.flaticon.com/512/2153/2153090.png" width="16" height="16" alt="Daphne" />
  return (    
    <>
        {/* <img src="https://cdn-icons-png.flaticon.com/512/616/616412.png" width="24" height="24" alt="Anton" style={{ marginRight: '10px' }} /> */}
        {/* <span style={{fontSize: '12px', fontFamily: 'Courier New'}}>
          Jetstreamr
        </span> */}
    </>
  );
}