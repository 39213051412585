import { Map } from './components/Map'
import { GroupCluster } from './components/GroupCluster'
import { useParams } from 'react-router-dom';
import React from 'react';
import { Backdrop, Button, CircularProgress } from '@mui/material';

export default function TestApp() {
  const { groupId } = useParams();

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <GroupCluster />
      <Map group={groupId} />
      <RecommendButton />
    </div>
  );
}

const RecommendButton = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ position: 'absolute', top: 30, right: 0, padding: '5px' }}>
      <Button variant="outlined" color="success" size="small" startIcon={<img src="https://cdn-icons-png.flaticon.com/128/3172/3172641.png" width="16" height="16" />} onClick={handleOpen}>
        Recommend
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex: 100 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );

}